import React, { Suspense } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Community from './portal-pages/community';
// Add AddCircle dependency to top bundle to avoid dependency ordering mismatch
// eslint-disable-next-line no-unused-vars
import AddCircle from './components/AddCircle';
// Add Select dependency to top bundle to avoid dependency ordering mismatch
// eslint-disable-next-line no-unused-vars
import './Portal.scss';


function Portal(props) {

    return (
        <div className="Portal">
            <BrowserRouter>
                <Suspense fallback={<div>Loading...</div>}>
                    <Route exact path="/" component={Community} />
                </Suspense>
            </BrowserRouter>
        </div>
    );
}

export default Portal;
