import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { rootUrl } from './config';
i18n
  // looks for translation files in /public/locales by default
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    saveMissing: true,
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
    },
    backend: {
      addPath: `${rootUrl}/locales/add/{{lng}}/{{ns}}`,
    },
  });

export default i18n;
