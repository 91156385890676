import React from 'react';
import styles from './index.module.scss';

const AppCard = (props) => {

    const onClick = (link) => {
        window.open(link, "_blank");
    };

    return (
        <div className={styles.container}>
            <a href={props.launchAppLink} target="blank">
                <img alt={props.title} className={styles.appImage} src={props.image} />
            </a>
            <div className={styles.appCardContent}>
                <div className={styles.appCardTitle}>{props.title}</div>
                <div className={styles.appCardBody}>{props.description}</div>
                <button className={styles.appCardSignIn} onClick={() => onClick(props.learnAboutLink)}>
                    {props.learnAboutText}
                </button>
                <button className={styles.appCardSignIn} onClick={() => onClick(props.launchAppLink)}>
                    {props.launchAppText}
                </button>
            </div>
        </div>
    );
};

export default AppCard;
