import React from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import SplashImage from '../../assets/images/portal/Community-Apps-Image.jpg';
import GageImage from '../../assets/images/portal/Community-Gage.jpg';
import OrcaImage from '../../assets/images/portal/Community-Orca.jpg';
import AppCard from '../../components/Portal/AppCard';

const Community = (props) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.splashSection}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${SplashImage})`,
        }}
      >
        <div className={styles.title}>{t('Community Apps')}</div>
      </div>
      <select
        className={styles.languageSelect}
        onChange={(e) => changeLanguage(e.target.value)}
        value={i18n.language}
      >
        <option value="en">English</option>
        <option value="es">Spanish</option>
        <option value="pt">Portuguese</option>
        <option value="it">Italian</option>
        <option value="fr">French</option>
        <option value="de">German</option>
        <option value="zh">Chinese</option>
      </select>
      <div className={styles.appsSection}>
        <div className={styles.subtitle}>{t('Community Apps')}</div>
        <AppCard
          image={GageImage}
          title={t('Gage') + ': ' + t('Discover brilliance')}
          description={t('A resource for journalists, educators, policy makers and others seeking the expertise of women and gender minorities STEMM professionals.')}
          launchAppText={t('Launch Gage')}
          launchAppLink={process.env.REACT_APP_PORTAL_LAUNCH_GAGE}
          learnAboutText={t('Learn About Gage')}
          learnAboutLink={process.env.REACT_APP_PORTAL_LEARN_GAGE}
        >
        </AppCard>
        <AppCard
          image={OrcaImage}
          title={t('Orca') + ': ' + t('Pod Management')}
          description={t('A global network of 500 Women Scientists chapters (Pods) where women and gender minorities can join, meet, advocate and develop local initiatives to change the world.')}
          launchAppText={t('Launch Orca')}
          launchAppLink={process.env.REACT_APP_PORTAL_LAUNCH_ORCA}
          learnAboutText={t('Learn About Orca')}
          learnAboutLink={process.env.REACT_APP_PORTAL_LEARN_ORCA}
        >
        </AppCard>
      </div>
    </div >
  );
};

export default Community;
